import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src//core/components/DpForm/DpForm2.vue'
import DpGrid from 'src//core/components/DpGrid/DpGrid2.vue'
import ClienteModel from 'src/model/usuario/ClienteModel.js'
import RoleModel from 'src/model/usuario/RoleModel.js'
import SituacaoUsuarioModel from 'src/model/usuario/SituacaoUsuarioModel.js'
import DpGridStore from 'src/store/dp-grid/'
import vTopFilters from 'src/core/filters/vTopFilters'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import { clone } from 'quasar'

export default {
  name: 'ClienteForm',
  components: { DpForm, DpGrid },
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters],
  data () {
    return {
      model: new ClienteModel(),
      roles: [],
      situacoes: [],
      isPwd: true,
      isPwdC: true,
      tab: 'dados',
      splitterModel: 25,

      salvando: false,
      salvarEFechar: false,

      tempMultiplasFile: [],
      storeUsuarioArquivo: 'usuarioArquivo',
      idPadrao: null,

      carregandoTiposLocais: false,
      tiposLocais: [],
      localAtualId: null,
      mostrarTipoLocal: false,
      salvandoTiposLocais: false,
      resultadoLojas: [],
      filtroLojas: ''
    }
  },
  meta () {
    return {
      title: 'Cliente Form',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },
  mounted () {
    if (this.$route.params.id) {
      let model = new RoleModel()
      model.getListagem().then((response) => {
        this.roles = response.dados
      }).catch((error) => {
        console.warn(error.msg)
      })
    }
    let model = new SituacaoUsuarioModel()
    model.getListagem().then((response) => {
      this.situacoes = response.dados
    }).catch((error) => {
      console.warn(error.msg)
    })
  },
  methods: {
    trataForm () {
      // Roles Selecionados
      const selecionadoRoles = []
      for (const index in this.model.form.roles) {
        if (this.model.form.roles[index].id) {
          selecionadoRoles.push(this.model.form.roles[index].id)
        }
      }

      if (selecionadoRoles.length > 0) {
        this.model.form.roles = selecionadoRoles
      }
    },

    rolesSelecionados () {
      let todasRoles = clone(this.roles)
      let rolesSelecionados = []
      if (todasRoles && todasRoles.length > 0) {
        todasRoles.map(ele => {
          if (this.roles.indexOf(ele.id) > -1) {
            rolesSelecionados.push(ele)
          }
        })
      }
      return rolesSelecionados
    },

    removeRolesSelecionado (id) {
      let roles = clone(this.model.form.roles)
      const index = roles.indexOf(id)

      if (index > -1) roles.splice(index, 1)
      this.form.roles = roles
    },
    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    onSubmitUsuario () {
      this.$refs.formPadrao.situacao = 'salvando'
      const aviso = this.notificacao('enviando')
      const send = clone(this.model.form)

      this.model.salvar(send).then((response) => {
        // atualizar rapido no grid
        const novoGrid = clone(this.$store.getters['dp-grid/getDados'][this.model.recurso])
        if (novoGrid !== undefined) {
          const filtrado = novoGrid.map((item) => {
            if (item.id === this.model.form.id) { return this.model.form } else { return item }
          })
          this.$store.commit('dp-grid/putDados', { recurso: this.model.recurso, payload: filtrado })
        }

        aviso()
        this.$refs.formPadrao.situacao = 'salvo'
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
        this.$refs.formPadrao.irParaListaInterno()
        this.$refs.formPadrao.resetarForm()
      }).catch(error => {
        aviso()
        this.$refs.formPadrao.situacao = 'erro'
        this.notificacao('erro', error.msg)
        console.error(error)
      })
    }
  }
}
