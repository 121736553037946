var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dp-form',{ref:"formPadrao",attrs:{"modelUtilizadoForm":_vm.model,"id":_vm.$route.params.id},on:{"acaoSubmitForm":_vm.onSubmitUsuario,"trataForm":_vm.trataForm}},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-6 col-xs-12 col-md-6"},[_c('q-input',{staticClass:"q-pb-md ValidEmail",attrs:{"label":"Nome","bg-color":"white","dense":"","rules":[function (val) { return !!val || 'Nome obrigatório'; }],"outlined":""},model:{value:(_vm.model.form.nome),callback:function ($$v) {_vm.$set(_vm.model.form, "nome", $$v)},expression:"model.form.nome"}})],1),_c('div',{staticClass:"col-6 col-xs-12 col-md-6"},[_c('q-input',{staticClass:"q-pb-md ValidEmail",attrs:{"label":"CPF","dense":"","bg-color":"white","rules":[function (val) { return !!val || 'CPF obrigatório'; }],"mask":"###.###.###-##","outlined":""},model:{value:(_vm.model.form.documento),callback:function ($$v) {_vm.$set(_vm.model.form, "documento", $$v)},expression:"model.form.documento"}})],1),_c('div',{staticClass:"col-6 col-xs-12 col-md-6"},[_c('q-input',{staticClass:"q-pb-md ValidEmail",attrs:{"type":"email","label":"E-mail","bg-color":"white","dense":"","rules":[function (val) { return _vm.validarEmail(val) || 'E-mail inválido'; }],"autocomplete":"new-username","outlined":"","clearable":""},model:{value:(_vm.model.form.email),callback:function ($$v) {_vm.$set(_vm.model.form, "email", $$v)},expression:"model.form.email"}})],1),_c('div',{staticClass:"col-6 col-xs-12 col-md-6"},[_c('q-input',{staticClass:"q-pb-md ValidEmail",attrs:{"label":"Celular","bg-color":"white","type":"tel","rules":[
          function (val) { return !!val || 'Celular obrigatório'; },
          function (val) { return val.length < 1 ||
            val.length > 14 ||
            'Complete o seu telefone'; }
        ],"autocomplete":"new-username","outlined":"","dense":"","mask":"(##) #####-####"},model:{value:(_vm.model.form.celular),callback:function ($$v) {_vm.$set(_vm.model.form, "celular", _vm._n($$v))},expression:"model.form.celular"}})],1),_c('div',{staticClass:"col-6 col-xs-12 col-md-6"},[_c('q-select',{staticClass:"q-pb-md custom-select",attrs:{"label":"Situação","bg-color":"white","dense":"","options":_vm.situacoes,"option-label":"nome","option-value":"id","rules":[function (val) { return !!val || 'Situação obrigatório'; }],"outlined":"","emit-value":"","map-options":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"select-icondown",attrs:{"name":"fa fa-angle-down"}})]},proxy:true}]),model:{value:(_vm.model.form.situacao_id),callback:function ($$v) {_vm.$set(_vm.model.form, "situacao_id", $$v)},expression:"model.form.situacao_id"}})],1),_c('div',{staticClass:"col-12"}),_c('div',{staticClass:"col-4 col-xs-12 col-md-6"},[_c('q-input',{staticClass:"ValidEmail",attrs:{"label":"Senha","stack-label":"","bg-color":"white","outlined":"","dense":"","type":_vm.isPwd ? 'password' : 'text',"autocomplete":"new-password"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"https"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.model.form.senha),callback:function ($$v) {_vm.$set(_vm.model.form, "senha", $$v)},expression:"model.form.senha"}})],1),_c('div',{staticClass:"col-4 col-xs-12 col-md-6"},[_c('q-input',{staticClass:"ValidEmail",attrs:{"label":"Confirmar senha","bg-color":"white","outlined":"","stack-label":"","dense":"","rules":[
          function (val) { return val === _vm.model.form.senha || "Senhas devem ser iguais"; }
        ],"type":_vm.isPwdC ? 'password' : 'text',"autocomplete":"new-password"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"https"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwdC ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwdC = !_vm.isPwdC}}})]},proxy:true}]),model:{value:(_vm.model.form.senha_confirmation),callback:function ($$v) {_vm.$set(_vm.model.form, "senha_confirmation", $$v)},expression:"model.form.senha_confirmation"}})],1)]),(_vm.$route.params.id)?_c('div',{staticClass:"row q-col-gutter-sm q-mt-sm"},[_c('div',{staticClass:"col-12 col-xs-12 col-md-12"},[_c('h6',{staticClass:"q-mt-none q-mb-sm",staticStyle:{"font-weight":"600"}},[_vm._v("\n        Permissões\n        "),(_vm.roles && _vm.roles.length > 0)?_c('q-chip',{attrs:{"size":"sm","removable":"","color":"primary","text-color":"white"},on:{"remove":function($event){_vm.model.form.roles = []}}},[_vm._v("Limpar Tudo")]):_vm._e()],1),_c('q-select',{staticClass:"q-pb-md custom-select",attrs:{"label":"Permissões","bg-color":"white","dense":"","options":_vm.roles,"option-value":"id","option-label":"name","outlined":"","emit-value":"","map-options":"","multiple":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"select-icondown",attrs:{"name":"fa fa-angle-down"}})]},proxy:true}],null,false,3977250005),model:{value:(_vm.model.form.roles),callback:function ($$v) {_vm.$set(_vm.model.form, "roles", $$v)},expression:"model.form.roles"}}),_vm._l((_vm.rolesSelecionados()),function(item,key){return _c('q-chip',{key:key,attrs:{"clickable":"","removable":"","color":"primary","text-color":"white","icon":"fa fa-times-circle"},on:{"click":function($event){return _vm.removeRolesSelecionado(item.id)}}},[_vm._v(_vm._s(item.name))])})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }