import PadraoModel from 'src/core/model/PadraoModel.js'

export default class RoleModel extends PadraoModel {
  recurso = 'roles';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'Roles';

  colunasGrid = [
    {
      name: 'acoes',
      label: 'Ações',
      field: 'acoes',
      style: 'width: 50px',
      align: 'left'
    },
    { name: 'id', label: 'ID', field: 'id' },
    { name: 'name', label: 'Nome', field: 'name' },
    { name: 'prioridade', label: 'Prioridade', field: 'prioridade' }
  ];

  form = {
    id: null,
    name: '',
    prioridade: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
